import { certificateControllerCertificatePDF } from './certificateControllerCertificatePDF'
import { certificateControllerCertificatePDFForA4 } from './certificateControllerCertificatePDFForA4'
import { certificateControllerCertificatePDFForEnrollment } from './certificateControllerCertificatePDFForEnrollment'
import { certificateControllerCertificatePDFForGraduation } from './certificateControllerCertificatePDFForGraduation'
import { certificateControllerCertificatePDFForProtocolExams } from './certificateControllerCertificatePDFForProtocolExams'
import { certificateControllerChangeAppointment } from './certificateControllerChangeAppointment'
import { certificateControllerCheckCertificate } from './certificateControllerCheckCertificate'
import { certificateControllerDeleteCertificate } from './certificateControllerDeleteCertificate'
import { certificateControllerFixExistingCertificates } from './certificateControllerFixExistingCertificates'
import { certificateControllerGenerateCertificate } from './certificateControllerGenerateCertificate'
import { certificateControllerGenerateContract } from './certificateControllerGenerateContract'
import { certificateControllerGenerateExamCard } from './certificateControllerGenerateExamCard'
import { certificateControllerGenerateExamResults } from './certificateControllerGenerateExamResults'
import { certificateControllerGenerateStudyConfirmation } from './certificateControllerGenerateStudyConfirmation'
import { certificateControllerGetLastCertificateByIin } from './certificateControllerGetLastCertificateByIin'
import { certificateControllerSearchCertificate } from './certificateControllerSearchCertificate'

export const certificateService = {
    certificateControllerFixExistingCertificates,
    certificateControllerGenerateCertificate,
    certificateControllerSearchCertificate,
    certificateControllerDeleteCertificate,
    certificateControllerCertificatePDF,
    certificateControllerCertificatePDFForA4,
    certificateControllerCheckCertificate,
    certificateControllerCertificatePDFForEnrollment,
    certificateControllerCertificatePDFForProtocolExams,
    certificateControllerCertificatePDFForGraduation,
    certificateControllerChangeAppointment,
    certificateControllerGenerateExamCard,
    certificateControllerGenerateContract,
    certificateControllerGenerateStudyConfirmation,
    certificateControllerGenerateExamResults,
    certificateControllerGetLastCertificateByIin,
  }